import { Medium } from '@sortlist-frontend/data-fetching/types';

import { useTracker } from '_core/hooks/use-tracker';

type Properties = {
  challenger?: boolean;
  edited?: boolean;
  step?: string;
  persona?: string;
  page?: string;
  schema?: string;
  lang?: string;
  direct?: boolean;
  comparator?: boolean;
  advisor?: boolean;
  skills?: boolean;
  location?: boolean;
  industry?: boolean;
  langs?: boolean;
  budget?: boolean;
  cta?: string;
  projectUuid?: string;
  projectId?: number;
  agencySlug?: string;
  absoluteEntryUrl?: string;
  sessionEntryUrl?: string;
  expertise?: number;
  job?: string;
  description?: boolean;
  phone?: boolean;
  stepId?: string;
  error?: string;
  [key: string]: any;
  commMedium?: Medium;
  commPref?: string;
  element?: string;
  sectionId?: string;
};

export const trackBriefingStarted = (props: Properties) => {
  const { trackUntyped } = useTracker();
  const {
    page,
    schema,
    lang,
    direct = false,
    comparator = false,
    advisor = false,
    skills,
    location,
    industry,
    langs,
    budget,
    cta,
    expertise,
    absoluteEntryUrl,
    sessionEntryUrl,
    projectUuid,
  } = props;

  trackUntyped('briefingStarted', {
    page,
    schema,
    lang,
    direct,
    comparator,
    advisor,
    skills,
    location,
    industry,
    expertise,
    langs,
    absoluteEntryUrl,
    sessionEntryUrl,
    budget,
    cta,
    projectUuid,
  });
};

export const trackBriefingCompleted = (props: Properties) => {
  const { trackUntyped } = useTracker();
  const {
    direct = false,
    comparator = false,
    job,
    description,
    phone,
    projectUuid,
    projectId,
    persona,
    absoluteEntryUrl,
    sessionEntryUrl,
  } = props;

  trackUntyped('briefingCompleted', {
    direct,
    comparator,
    job,
    description,
    phone,
    projectUuid,
    projectId,
    category: persona,
    absoluteEntryUrl,
    sessionEntryUrl,
    ...props,
  });
};

export const trackProjectCreated = (props: Properties) => {
  const { projectUuid, projectId, persona: category, absoluteEntryUrl, absoluteEntryDate, sessionEntryUrl } = props;
  const { trackUntyped } = useTracker();
  trackUntyped('projectCreated', {
    projectUuid,
    projectId,
    category,
    absoluteEntryUrl,
    absoluteEntryDate,
    sessionEntryUrl,
  });
};

export const trackBriefingError = (props: Properties) => {
  const { direct = false, comparator = false, error, projectUuid } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('briefingError', { direct, error, comparator, projectUuid });
};

export const trackBriefingClosed = (props: Properties) => {
  const { projectUuid, step, persona } = props;
  const { trackUntyped } = useTracker();
  trackUntyped('briefingClosed', {
    projectUuid,
    stepId: step,
    category: persona,
  });
};

export const trackBriefingContinued = (props: Properties) => {
  const { projectUuid, step } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('briefingContinued', {
    projectUuid,
    stepId: step,
  });
};

export const trackBriefingCanceled = (props: Properties) => {
  const { projectUuid, step, persona } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('briefingCanceled', {
    projectUuid,
    stepId: step,
    category: persona,
  });
};

export const trackStepAnswered = (props: Properties, callback?: () => void) => {
  const { projectUuid, step, persona, answers, commMedium, commPref, ...restOfProps } = props;
  const { trackUntyped, trackWithCallback } = useTracker();

  const data = {
    projectUuid,
    stepId: step,
    label: step,
    category: persona,
    commMedium,
    commPref,
    [step as string]: answers,
    ...restOfProps,
  };

  if (!callback) return trackUntyped('stepAnswered', data);

  trackWithCallback('stepAnswered', data, callback);
};

export const trackStepLoaded = (props: Properties) => {
  const { projectUuid, step, persona, commMedium, commPref, ...restOfProps } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('stepLoaded', {
    projectUuid,
    stepId: step,
    label: step,
    category: persona,
    commMedium,
    commPref,
    ...restOfProps,
  });
};

export const trackShowNextSteps = (props: Properties) => {
  const { element, stepID, ...restOfProps } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('briefingShowMore', {
    element,
    stepID,
  });
};

export const trackSkipContactPreferences = (props: Properties, callback: () => void) => {
  const { projectUuid, element, ...restOfProps } = props;
  const { trackWithCallback } = useTracker();

  trackWithCallback(
    'prefSkipped',
    {
      projectUuid,
      element,
    },
    callback,
  );
};

export const trackBriefingEdited = (props: Properties) => {
  const { edited, element, page, schema, sectionId, stepId, projectId } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('editionSaved', {
    edited,
    element,
    page,
    schema,
    sectionId,
    stepId,
    projectId,
  });
};
