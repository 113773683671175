import { cx } from '@emotion/css';
import { css, Global } from '@emotion/react';
import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import { Button, Loader, useMediaQuery } from '@sortlist-frontend/design-system';
import { createRef, FC, ReactElement, RefObject, useEffect, useRef, useState, Fragment } from 'react';
import LazyLoad from 'react-lazyload';

import { MediaAttachment } from '_backend/queries/shared/media-attachment';
import { usePublicAppContext } from '_core/context/public-app-context';
import { buildVideoSrcUrl, getGumletImg, getThumbImage, onImageError } from '_core/media/get-media.utils';

import * as S from './styles';

type Props = {
  name?: string;
  slides?: MediaAttachment[];
};

const defaultWork = '/_img/default/default-image-square.svg';
export const thumbnailWidth = 112;
export const thumbnailHeight = 64;

const displayMediaAttachment = (
  media: MediaAttachment,
  name: string | undefined,
  mobile: boolean,
  loading: boolean,
  setLoading: (value: boolean) => void,
  origin?: string | null,
) => {
  switch (media.type) {
    case 's3':
      return (
        <Fragment>
          {loading && <Loader size="lg" color="secondary-500" />}
          <S.Slide
            data-testid={'carousel-img'}
            className="bg-secondary-100"
            src={getGumletImg(media, { preferredWidth: 1024, mobile })}
            alt={name}
            onError={onImageError}
            onLoad={() => setLoading(false)}
          />
        </Fragment>
      );
    case 'vimeo':
    case 'youtube':
      return (
        <Fragment>
          {loading && <Loader size="lg" color="secondary-500" />}
          <S.FrameWrapper className="height-0 relative overflow-hidden">
            <S.Frame
              className="bg-content-900 flex"
              src={buildVideoSrcUrl(media.key, media.type, origin || '')}
              title={name}
              onLoad={() => setLoading(false)}
            />
          </S.FrameWrapper>
        </Fragment>
      );
    default:
      return <S.Slide className="shadow-2 bg-content-900" src={defaultWork} onLoad={() => setLoading(false)} />;
  }
};

type ButtonProps = {
  className?: string;
  disabled?: boolean;
  direction: 'back' | 'forward';
  onClick: () => void;
  icon: ReactElement;
  testId?: string;
};

const SliderButtons: FC<ButtonProps> = (props) => {
  const { className, disabled, direction, onClick, icon, testId } = props;

  return (
    <S.SliderButton direction={direction} className={cx(className, 'absolute')}>
      <Button
        data-testid={testId}
        fab={true}
        size="xs"
        iconSize="md"
        buttonStyle="secondary"
        buttonVariant="light"
        icon={icon}
        onClick={onClick}
        disabled={disabled}
        id={`carousel-${direction}-slider-btn`}
      />
    </S.SliderButton>
  );
};
// eslint-disable-next-line sonarjs/cognitive-complexity
export const Carousel: FC<Props> = (props) => {
  const { name, slides } = props;
  const { domainInfo } = usePublicAppContext();
  const { media } = useMediaQuery();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [positions, setPositions] = useState<(number | undefined)[]>([]);
  const [scrollX, setScrollX] = useState<number | undefined>();
  const [sliderHasOverflow, setSliderHasOverflow] = useState(true);
  const [firstThumbnailVisible, setFirstThumbnailVisible] = useState(true);
  const [lastThumbnailVisible, setLastThumbnailVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [slideHeight, setSlideHeight] = useState<number | undefined>(0);

  const sliderRef = useRef<HTMLDivElement>(null);
  const slideWrapperRef = useRef<HTMLDivElement>(null);
  const thumbnailsWrapperRef = useRef<HTMLDivElement>(null);
  const thumbnailsRefs = useRef<RefObject<HTMLImageElement>[]>(
    [...new Array(slides?.length)]?.map(() => createRef<HTMLImageElement>()),
  );

  const thumbnailsWrapper = thumbnailsWrapperRef.current;
  const thumbnailsWrapperWidth = thumbnailsWrapper?.offsetWidth ?? 0;
  const allThumbnailsWidth = slides ? 64 * slides?.length : 0;

  const firstSlide = currentSlide === 0;
  const lastSlide = slides?.length ? currentSlide === slides.length - 1 : true;
  const lastThumbnail = slides && thumbnailsRefs.current?.[slides?.length - 1].current;

  useEffect(() => {
    if (scrollX && scrollX > thumbnailWidth) {
      setFirstThumbnailVisible(false);
    } else {
      setFirstThumbnailVisible(true);
    }
  }, [scrollX]);

  useEffect(() => {
    if (scrollX && lastThumbnail && scrollX + thumbnailsWrapperWidth > lastThumbnail?.offsetLeft) {
      setLastThumbnailVisible(true);
    } else {
      setLastThumbnailVisible(false);
    }
  }, [scrollX]);

  const getScrollX = () => {
    const scrollLeft = thumbnailsWrapper?.scrollLeft;
    setScrollX(scrollLeft);
  };

  useEffect(() => {
    thumbnailsWrapper?.addEventListener('scroll', getScrollX, true);
    return () => thumbnailsWrapper?.removeEventListener('scroll', getScrollX, true);
  }, [thumbnailsWrapper]);

  useEffect(() => {
    if (slideWrapperRef) {
      const currentSlideHeight = slideWrapperRef.current?.getBoundingClientRect().height;
      setSlideHeight(currentSlideHeight);
    }
  }, [loading]);

  useEffect(() => {
    const sliderOverflow = thumbnailsWrapperWidth < allThumbnailsWidth;
    setSliderHasOverflow(sliderOverflow);
  }, []);

  const scrollToThumbnail = (index: number) => {
    const position: number = positions?.[index] ?? 0;
    const scrollY = position - (thumbnailsWrapperWidth / 2 - thumbnailWidth / 2);
    thumbnailsWrapper?.scrollTo(scrollY, 0);
  };

  useEffect(() => {
    if (thumbnailsRefs.current) {
      setPositions(thumbnailsRefs?.current?.map((ref) => ref?.current?.offsetLeft));
    }
  }, [thumbnailsRefs]);

  const showSliderArrowButtons = slides && slides.length > 1 && sliderHasOverflow;

  const nextSlide = () => {
    if (lastSlide) return;
    setLoading(true);
    setCurrentSlide(currentSlide + 1);
    scrollToThumbnail(currentSlide + 1);
  };

  const prevSlide = () => {
    if (!currentSlide) return;
    setLoading(true);
    setCurrentSlide(currentSlide - 1);
    scrollToThumbnail(currentSlide - 1);
  };

  const onThumbnailClick = (index: number) => {
    setLoading(true);
    setCurrentSlide(index);
    scrollToThumbnail(index);
  };

  return (
    <Fragment>
      <Global
        styles={css`
          #work-thumbnails-wrapper {
            scroll-behavior: smooth;
          }
        `}
      />
      {slides && slides.length > 1 ? (
        <div className="hide-xs relative">
          <S.WorkThumbnailGradient firstSlide={firstThumbnailVisible} lastSlide={lastThumbnailVisible} ref={sliderRef}>
            <S.WorkThumbnailsWrapper
              data-testid="work-thumbnails-wrapper"
              id="work-thumbnails-wrapper"
              className="overflow-y-scroll layout-row layout-align-start-center relative"
              ref={thumbnailsWrapperRef}
              thumbnailHeight={thumbnailHeight}>
              {slides?.map((slide, index) => (
                <S.WorkThumbnail
                  thumbnailHeight={thumbnailHeight}
                  thumbnailWidth={thumbnailWidth}
                  ref={thumbnailsRefs.current[index]}
                  key={`${slide.url}-${index}`}
                  className={cx(
                    { 'shadow-2': currentSlide === index },
                    { 'ml-12': index === 0 },
                    index + 1 === slides.length ? 'mr-12' : 'mr-8',
                    'cursor-pointer rounded-sm',
                  )}
                  src={getThumbImage(slide)}
                  alt={`${name}-${index}`}
                  onClick={() => onThumbnailClick(index)}
                  onError={onImageError}
                  active={currentSlide === index}
                  data-testid="work-thumbnail"
                />
              ))}
            </S.WorkThumbnailsWrapper>
          </S.WorkThumbnailGradient>
          {showSliderArrowButtons ? (
            <Fragment>
              {!firstSlide && (
                <SliderButtons
                  direction={'back'}
                  onClick={prevSlide}
                  icon={<ArrowBackRounded style={{ fontSize: 16 }} />}
                  testId={'carousel-back'}
                />
              )}
              {!lastSlide && (
                <SliderButtons
                  direction={'forward'}
                  onClick={nextSlide}
                  icon={<ArrowForwardRounded style={{ fontSize: 16 }} />}
                  testId={'carousel-forward'}
                />
              )}
            </Fragment>
          ) : null}
        </div>
      ) : null}
      <S.SlideWrapper
        ref={slideWrapperRef}
        isLoading={loading}
        height={slideHeight}
        className={cx({ 'rounded-lg': media.gtXs }, 'bg-secondary-100 relative overflow-hidden')}
        data-testid={'carousel'}>
        {slides ? (
          slides.map(
            (slide, index) =>
              currentSlide === index && (
                <LazyLoad
                  key={`${slide.url}$-{index}`}
                  height={'100%'}
                  once={true}
                  offset={100}
                  scroll={true}
                  overflow={true}
                  className={'layout-column'}>
                  {displayMediaAttachment(slide, name, media.xs, loading, setLoading, domainInfo?.getOriginUrl())}
                </LazyLoad>
              ),
          )
        ) : (
          <S.Slide className="height-100 width-100 bg-content-100" src={defaultWork} onLoad={() => setLoading(false)} />
        )}
        {slides && slides.length > 1 ? (
          <Fragment>
            <div className="hide-gt-xs width-100 absolute px-16" style={{ bottom: '0.5rem' }}>
              <S.DotWrapper className="layout-wrap layout-row layout-align-center-center width-100">
                {slides.map((slide, index) => (
                  <S.Dot
                    key={slide.url}
                    onClick={() => setCurrentSlide(index)}
                    className="p-4 mr-4 mb-4 cursor-pointer rounded bg-secondary-100"
                    active={currentSlide === index}
                  />
                ))}
              </S.DotWrapper>
            </div>
            <SliderButtons
              className={'hide-gt-xs'}
              disabled={!currentSlide}
              direction={'back'}
              onClick={prevSlide}
              icon={<ArrowBackRounded />}
            />
            <SliderButtons
              className={'hide-gt-xs'}
              disabled={lastSlide}
              direction={'forward'}
              onClick={nextSlide}
              icon={<ArrowForwardRounded />}
            />
          </Fragment>
        ) : null}
      </S.SlideWrapper>
    </Fragment>
  );
};
