import { cx } from '@emotion/css';
import { Tooltip } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import Image from 'next/image';
import { FC } from 'react';

import { GetAgencyPortfolio } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';
import { onImageError } from '_core/media/get-media.utils';

import { workDetailModalConfig } from '../config';
import * as S from '../styles';
import { getWorkDescription, getWorkSingleDate } from '../utils';

type Props = {
  className?: string;
  work: GetAgencyPortfolio['works'][0];
  onClick?: (workSlug: string) => void;
};

export const WorkCard: FC<Props> = (props) => {
  const { work, onClick = () => {}, className } = props;

  const date = getWorkSingleDate({
    start_date: work.start_date,
    end_date: work.end_date,
  });

  const { t } = useTranslation(workDetailModalConfig.i18nNamespaces);
  const expertisesLimit = 3;

  return (
    <div className={cx(className, 'layout-column')}>
      <S.WorkCardThumbnail>
        <img
          src={ImageSourceHelper.fromUrl(work.attachment.url, {
            width: 960,
          })}
          loading={'lazy'}
          alt={work.name}
          onError={onImageError}
          className="cursor-pointer width-100 rounded-md shadow-2"
          onClick={() => onClick(work.slug)}
          onKeyDown={() => onClick(work.slug)}
          role="presentation"
        />
      </S.WorkCardThumbnail>
      <div className="layout-column py-16">
        {work.name ? <span className="strong p mb-4">{work.name}</span> : null}
        <p className="small mb-8">
          <p className="small mb-8">{getWorkDescription(work)}</p>
          {/* Line below added and hidden for SEO purposes */}
          {work.description && <span className="hide">{work.description}</span>}
        </p>
        {work.expertises && (
          <div className="layout-row layout-wrap">
            {work.expertises.slice(0, expertisesLimit).map((expertise, index) => (
              <span
                key={expertise}
                className="mr-4 mb-4 small px-8 py-4 rounded-sm bg-secondary-300 text-secondary-900">
                {expertise}
              </span>
            ))}
            {work.expertises.length > expertisesLimit ? (
              <Tooltip title={work.expertises.slice(expertisesLimit, work.expertises.length).join(', ')}>
                <span className="cursor-pointer mb-4 py-4 small px-8 rounded-sm bg-secondary-300 text-secondary-900">{`+${
                  work.expertises.length - expertisesLimit
                }`}</span>
              </Tooltip>
            ) : null}
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onKeyDown={() => onClick(work.slug)}
          onClick={() => onClick(work.slug)}
          className="small underline cursor-pointer text-primary-500"
          role="link"
          tabIndex={0}>
          {t('portfolio:work.readMore')}
        </a>
      </div>
    </div>
  );
};
